import { RouterModule } from '@angular/router';
import { NotAuthorizedComponent } from 'csx-ng';
import { environment } from './../environments/environment';
import { LoadBundleGuard } from './guards/load-bundle.guard'
import { WebComponentWrapperComponent } from './web-component-wrapper/web-component-wrapper.component';
export const routing = RouterModule.forRoot(
  [
    {
      path: 'web-component',
      component: WebComponentWrapperComponent,
      canActivate: [LoadBundleGuard],
      data: {
        bundle: environment.appConfig.webComponents.home
      }
    },     
    {
      path: 'notauthorized',
      component: NotAuthorizedComponent,
      data: {
        adminEmail: 'app-admin@csx.com'
      }
    }, {
      path: '',
      loadChildren: () => import("./Modules/railcar-management/railcar-management.module").then(m => m.RailcarManagementModule)
    },
  ],
  { useHash: true }
);
