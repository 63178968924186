export const appConfigMapping = {
  security: {
    securityDomains: (window as any).appConfig?.security?.securityDomains,
    clientId: (window as any).appConfig?.security?.clientId,
    autoConfigUrl: (window as any).appConfig?.security?.autoConfigUrl,
    mapboxRCToken: (window as any).mapRailCarsConstants?.mapboxRCToken,
    mapRCStyleMono: (window as any).mapRailCarsConstants?.mapRCStyleMono,
    mapRCStyleDark: (window as any).mapRailCarsConstants?.mapRCStyleDark,
    mapRCStyleSatellite: (window as any).mapRailCarsConstants?.mapRCStyleSatellite,
    mapRCStyleRoad: (window as any).mapRailCarsConstants?.mapRCStyleRoad
  },
  api: {
    railCarsApi: (window as any).appConfig?.api.railCarsApi,
    opsNetworkApi: (window as any).appConfig?.api.opsNetworkApi,
    trainApi: (window as any).appConfig?.api.trainApi,
    yardCentralApi: (window as any).appConfig?.api.yardCentralApi,
    mainApiUrl: (window as any).appConfig?.api.mainApiUrl,
    pipelineApiUrl: (window as any).appConfig?.api.pipelineApiUrl
  },
  webComponents: {
    home: (window as any).appConfig?.webComponents?.home
  },
  googleAnalyticsTrackingId: (window as any).appConfig?.googleAnalyticsTrackingId,
  googleAnalyticsURL: (window as any).appConfig?.googleAnalyticsURL
};
