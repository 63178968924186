<csx-header-with-menu-and-page-name-template>
  <div page-header>
    <csx-header [model]="headerModel"></csx-header>
  </div>
  <div page-content>
    <div class="content-wrapper">
      <router-outlet></router-outlet>
    </div>
  </div>
</csx-header-with-menu-and-page-name-template>

