import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'csx-web-component-wrapper',
  templateUrl: './web-component-wrapper.component.html',
})
export class WebComponentWrapperComponent implements OnInit {
  constructor() {}
  ngOnInit(): void {}
}
