import { Injectable, Injector } from '@angular/core';
import { AuthenticationService } from '@csx/security';
import { Router } from '@angular/router';
import { LocationStrategy } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class SessionHandlerService {
  _authService: AuthenticationService | null = null;
  _router: Router | null = null;
  previousUserId: string | null = null;
  constructor(
    protected injector: Injector,
    private locationStrategy: LocationStrategy
  ) {}

  public initialize(): Promise<any> {
    return new Promise<void>(resolve => {
      this.authService.onUserUpdated.subscribe(user => {
        if (
          this.previousUserId &&
          (user.userId !== this.previousUserId || !user.userId)
        ) {
          let locationPath = this.locationStrategy.path(true);
          this.router.navigateByUrl('/home');
          setTimeout(() => this.router.navigateByUrl(locationPath), 1);
        }
        this.previousUserId = user.userId;
      });
      resolve();
    });
  }

  protected get authService(): AuthenticationService {
    if (this._authService === null) {
      this._authService = this.injector.get(AuthenticationService);
    }

    if (this._authService === undefined || this._authService === null) {
      throw new Error(
        'Null was injected for AuthenticationService; this is most likely a side effect of other errors. If this is not the case then please notify the library creator.'
      );
    }

    return this._authService;
  }

  protected get router(): Router {
    if (this._router === null) {
      this._router = this.injector.get(Router);
    }

    if (this._router === undefined || this._router === null) {
      throw new Error(
        'Null was injected for Router; this is most likely a side effect of other errors. If this is not the case then please notify the library creator.'
      );
    }

    return this._router;
  }
}
