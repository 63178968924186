import {
  APP_BASE_HREF,
  DatePipe,
  HashLocationStrategy,
  Location,
  LocationStrategy
} from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  APP_INITIALIZER,
  NO_ERRORS_SCHEMA
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  CsxSecurityModule,
  SecurityConfigInjectionToken,
  CookieStorageService
} from '@csx/security';
import { LoadBundleGuard } from './guards/load-bundle.guard';
/* CSX-SPECIFIC */
import {
  AppUser,
  CsxInterceptor,
  CsxNgModule,
  CsxNgTemplatesModule
} from 'csx-ng';

/* APP-SPECIFIC */
import { AppComponent } from './app.component';
import { routing } from './app.routing';
import { SecConfig } from './security/security.config';
import { WebComponentWrapperComponent } from './web-component-wrapper/web-component-wrapper.component';
import { WindowRef } from './windowref';
import { SessionHandlerService } from './services/session-handler.service';
import { LoaderInterceptorService } from './shared/services/loader/loader-interceptor.service';
export function sessionCacheTest(loader: SessionHandlerService) {
  return () => loader.initialize();
}

@NgModule({
  declarations: [AppComponent, WebComponentWrapperComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    routing,
    CsxNgModule,
    CsxNgTemplatesModule,
    CsxSecurityModule.initialize()
  ],
  providers: [
    AppUser,
    LoadBundleGuard,
    WindowRef,
    DatePipe,
    CookieStorageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptorService,
      multi: true
    },
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: SecurityConfigInjectionToken, useValue: SecConfig },
    [
      Location,
      { provide: LocationStrategy, useClass: HashLocationStrategy },
      { provide: SecurityConfigInjectionToken, useValue: SecConfig }
    ],
    {
      provide: APP_INITIALIZER,
      useFactory: sessionCacheTest,
      deps: [SessionHandlerService],
      multi: true
    },
    [
      Location,
      { provide: LocationStrategy, useClass: HashLocationStrategy },
      { provide: SecurityConfigInjectionToken, useValue: SecConfig }
    ]
  ],

  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class AppModule {}
