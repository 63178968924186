import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

function _window(): any {
  // return the global native browser window object
  return window;
}

@Injectable()
export class WindowRef {
  isSticky = false;
  private stickySource = new Subject<boolean>();
  isSticky$ = this.stickySource.asObservable();
  scrollTopPosition: number;
  get nativeWindow(): any {
    return _window();
  }

  setSticky(sticky: boolean) {
    this.stickySource.next(sticky);
  }

  getSticky() {
    this.stickySource.next(this.isSticky);
  }
}
