import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { finalize, Observable } from 'rxjs';
import { LoaderService } from './loader.service';
import { LOADER_CONST } from '../../../../app/Constants/loader.constant';

@Injectable()
export class LoaderInterceptorService implements HttpInterceptor {
  private requestCount = 0;

  constructor(private loaderService: LoaderService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const hasDisableLoader = req.params.get(LOADER_CONST.DISABLE_LOADER);

    if (hasDisableLoader) {
      req = req.clone({
        params: req.params.delete(LOADER_CONST.DISABLE_LOADER)
      });
    } else {
      this.loaderService.isLoading.next(true);
      this.requestCount++;
    }

    return next.handle(req).pipe(
      finalize(() => {
        if (!hasDisableLoader) {
          this.requestCount--;

          if (this.requestCount === 0) {
            return this.loaderService.isLoading.next(false);
          }
        }
        return null;
      })
    );
  }
}
