import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AuthenticationService, User } from '@csx/security';
import { CsxHeaderModel } from 'csx-ng';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = "Car Gps";
  headerModel: CsxHeaderModel;
  headerModelRole: CsxHeaderModel;

  constructor(public appUser: User, public authService: AuthenticationService, private cd: ChangeDetectorRef) {
   }

  ngOnInit(): void {
    this.authService.onUserUpdated.subscribe((user: User) => {
      this.headerModel.userName = user.displayName;
      this.headerModel.userRacf = user.racf;
      this.headerModelRole.userRole = user.roles[0];
      this.cd.detectChanges();
    });

    this.headerModel = {
      userName: this.appUser ? this.appUser.displayName : 'Unknown',
      userRacf: this.appUser ? this.appUser.racf : null,
      appShortName: 'RCT',
      appLongName: 'Rail Car Tracking',
      needsSignal: false,
      needsAlert: false,
      needsLogout: false,
      isAppOnline: false,
      alertCount: 0,
      needsUserProfile: false,
    };
    this.headerModelRole = {
      userName: this.appUser.displayName || "John Doe",
      userRole: this.appUser ? this.appUser.roles[0] : "Admin",
      appShortName: 'RCT',
      appLongName: 'Rail Car Tracking',
      needsSignal: false,
      needsAlert: false,
      needsLogout: false,
      isAppOnline: false,
      alertCount: 0,
      needsUserProfile: false,
    };
  }

}
