import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivate
} from '@angular/router';
import { BundleLoaderService } from 'csx-ng';

@Injectable()
export class LoadBundleGuard implements CanActivate {
 
  constructor(private _bundleLoader: BundleLoaderService) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    // ensure that we reset the attribute before checking
    
   let bundleLoaded: Promise<boolean> = new Promise((resolve, reject) => {
        this._bundleLoader.loadBundle(
            route.data.bundle,
        () => {
            console.log("bundle loaded");
            resolve(true);
        },
        () => {
            console.error('FAILED TO LOAD BUNDLE!');
            reject(false);
        }
        );
    });
      
    return await bundleLoaded;
  }
}